import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Progress,
  Text,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { customStyles } from "../Meeting/Attendance";

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      valueContainer: (provided, state) => ({
        ...provided,
        background: "#fff",
        borderColor: "#9e9e9e",
        minHeight: "5indicatorsContainer0px",
        height: "80px",
        boxShadow: state.isFocused ? null : null,
        zIndex: "14343",
      }),

      backgroundColor: isDisabled ? "red" : "whitesmoke",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "5px",
      marginBottom: "5px",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#000" : "#000"),
      },
    };
  },
};

export const Voting = () => {
  const { token } = useAuth();
  const [nomineeData, setNomineeData] = useState([]);
  const toast = useToast();
  const [meeting, selectMeeting] = useState({
    meetingId: "",
    meetingTitle: "",
  });
  const [agenda, setAgenda] = useState({
    agendaId: "",
    agendaTitle: "",
  });

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const meetingData = useQuery(
    ["meetingDataForCard"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meetings`,

        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  const getNominees = useQuery(
    `getNominees", ${meeting?.meetingTitle}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}votes/nominees/${meeting?.meetingId}`,
        {
          headers,
          params: {
            agenda_id: agenda?.agendaId,
          },
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token && !!meeting?.meetingId,
      onSuccess: (res) => {
        console.log(res.data.data);
        setNomineeData(res?.data?.data?.nominees);
      },
    }
  );
  const deleteNominee = useMutation(
    "deleteNominee",
    async (id) =>
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}votes/nominees/${id}`,
        {
          headers,
        }
      ),
    {
      onSuccess: (res) => {
        toast({
          title: "Vote Revoked Successfully",
          description: " Nominee Vote Revoked Successfully ",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        getNominees.refetch();
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const navigate = useNavigate();
  React.useEffect(() => {
    setNomineeData([]);
  }, [meetingData.data?.data, meeting?.meetingId, agenda?.agendaId]);

  React.useEffect(() => {
    if (meetingData.data?.data?.data?.length > 0) {
      selectMeeting({
        meetingId: meetingData.data?.data?.data[0]?.id,
        meetingTitle: meetingData.data?.data?.data[0]?.title,
      });
    }
  }, [meetingData.data?.data?.data]);
  console.log("agenda =>", getNominees.data?.data?.data?.agendas);
  console.log("selected agenda =>", agenda);

  React.useEffect(() => {
    getNominees.refetch();
  }, [agenda?.agendaId]);

  const finalizeNomineeVotes = useMutation(
    "finalizeNomineeVotes",
    async (id) =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}votes/nominees/finalize/${id}`,
        { headers }
      ),
    {
      onSuccess: (res) => {
        toast({
          title: "Nominee Votes Finalized Successfully",
          description: " Nominee Votes Finalized Successfully ",
          status: "success",
          duration: 9000,

          isClosable: true,
        });
        getNominees.refetch();
        navigate("/voting");
      },
      onError: (err) => {
        toast({
          status: "error",
          title: "Error",
          description: ` ${err?.response?.data?.message}`,
        });
      },
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "80%",
      }}
    >
      <Heading margin={"20px"} textAlign={"center"} fontSize="xl">
        Voting Management
      </Heading>

      <Wrap
        direction={"row"}
        gap={4}
        spacing={4}
        mx={8}
        alignItems={"center"}
        justify={"space-between"}
      >
        <WrapItem
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: 3,
          }}
        >
          <Heading size="md" my={1}>
            Select Meeting
          </Heading>

          <Select
            styles={customStyles}
            placeholder="Select Meeting"
            defaultValue={
              meetingData.data?.data?.data?.length > 0
                ? {
                    id: meetingData.data?.data?.data[0]?.id,
                    title: meetingData.data?.data?.data[0]?.title,
                  }
                : null
            }
            isDisabled={meetingData.isLoading}
            options={meetingData.data?.data?.data}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            isLoading={meetingData.isLoading}
            isClearable
            onChange={(e) => {
              selectMeeting({
                meetingId: e?.id,
                meetingTitle: e?.title,
              });
            }}
          />
        </WrapItem>

        {getNominees?.data?.data?.data?.agendas?.length >= 1 && (
          <WrapItem
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 3,
            }}
          >
            <Heading size="md" my={1}>
              Select Agenda
            </Heading>

            <Select
              styles={customStyles}
              placeholder="Select Agenda"
              defaultValue={
                getNominees.data?.data?.data?.agendas?.length > 0
                  ? {
                      id: getNominees.data?.data?.data?.agendas[0]?.id,
                      title: getNominees.data?.data?.data?.agendas[0]?.title,
                    }
                  : null
              }
              value={getNominees.data?.data?.data?.agendas[0]}
              isDisabled={getNominees.isLoading}
              options={getNominees.data?.data?.data?.agendas}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.id}
              isLoading={getNominees.isLoading}
              isClearable
              onChange={(e) => {
                setAgenda({
                  agendaId: e?.id,
                  agendaTitle: e?.title,
                });
              }}
            />
          </WrapItem>
        )}
        <WrapItem>
          <Button
            sx={{
              backgroundColor: "#E82481",
              color: "#FFF",
              borderRadius: "10px",
              alignSelf: "center",
              _hover: {
                backgroundColor: "#E82481",
                color: "#FFF",
              },
            }}
            onClick={() => {
              finalizeNomineeVotes.mutate(meeting?.meetingId);
            }}
            isLoading={finalizeNomineeVotes.isLoading}
          >
            Finalize Vote
          </Button>
          <Button
            sx={{
              backgroundColor: "#E82481",
              color: "#FFF",
              borderRadius: "10px",
              alignSelf: "center",
              mx: 5,
              _hover: {
                backgroundColor: "#E82481",
                color: "#FFF",
              },
            }}
            onClick={() => {
              // finalizeNomineeVotes.mutate(meeting?.meetingId);
              navigate(`/multi-vote/${meeting?.meetingId}`, {
                state: {
                  agendaId: agenda?.agendaId,
                },
              });
            }}
            // isLoading={finalizeNomineeVotes.isLoading}
            disabled={
              getNominees?.data?.data?.data?.agendas?.length <= 0 ||
              !agenda?.agendaId
            }
          >
            Add Vote
          </Button>
        </WrapItem>
      </Wrap>

      <MaterialTable
        isLoading={getNominees.isLoading}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <Text
                sx={{
                  fontSize: "md",
                  fontWeight: "bold",
                  color: "gray.500",
                  textAlign: "center",
                }}
              >
                Select Meeting To Get list of Nominee
              </Text>
            ),
          },
        }}
        style={{
          zIndex: "0",
          margin: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        options={{
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          rowStyle: {
            color: "#000",
            backgroundColor: "#FFF",
          },
          searchFieldStyle: {
            backgroundColor: "#FFF",
            color: "#000",
          },

          padding: "default",
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Nominee List",
          sorting: [
            {
              id: "percent",
              desc: true,
            },
            {
              id: "votes",
              desc: true,
            },
          ],
        }}
        title="Nominee List"
        columns={[
          {
            title: "ስም",
            field: "name_amharic",
          },
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Phone",
            field: "phone",
          },

          {
            title: "Percent",
            field: "percent",
            type: "numeric",
            defaultSort: "desc",
            render: (rowData) => (
              <Text>
                {Number(Math.round(rowData.percent * 10) / 10).toLocaleString()}
              </Text>
            ),
          },
          {
            title: "Total Capital",
            field: "capital",
            type: "numeric",
            // defaultSort: "desc",
            export: false,
            render: (rowData) => (
              <Text>
                {Number(
                  Math.round(rowData.votes_capital * 10) / 10
                ).toLocaleString()}
              </Text>
            ),
          },
          {
            title: "Total Votes(Share)",
            field: "votes",
            type: "numeric",
            defaultSort: "desc",
            export: false,

            render: (rowData) => (
              <Text>
                {Number(
                  Math.round(rowData.votes_share * 10) / 10
                ).toLocaleString()}
              </Text>
            ),
          },

          // {
          //   title: "Vote",
          //   field: "actions",
          //   sorting: false,
          //   render: (rowData) =>
          //     rowData?.is_finalized == 0 && (
          //       <Button
          //         bg={"#B76A1D"}
          //         color={"white"}
          //         variant="solid"
          //         _hover={{ bg: "#B76A1D" }}
          //         onClick={() => {
          //           navigate(`/voting/${rowData?.id}`, {
          //             state: {
          //               meetingId: meeting?.meetingId,
          //               meetingTitle: meeting?.meetingTitle,
          //               nomineeName: rowData.name,
          //               proxies: getNominees.data?.data?.data?.proxies,
          //               stakeholder_id:
          //                 getNominees.data?.data?.data?.stakeholders,
          //             },
          //           });
          //         }}
          //       >
          //         Add Vote
          //       </Button>
          //     ),
          // },
        ]}
        data={nomineeData}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(rowData) => {
          return (
            <Box>
              <MaterialTable
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <Text
                        sx={{
                          fontSize: "md",
                          fontWeight: "bold",
                        }}
                      >
                        No Votes Yet
                      </Text>
                    ),
                  },
                }}
                style={{
                  border: "1px solid #E2E8F0",
                  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                  borderRadius: "15px",
                  marginLeft: "7%",
                  marginRight: "7%",
                }}
                title="List of Voters"
                columns={[
                  // {
                  //   title: "#",
                  //   render: (rowData) =>
                  //     rowData?.voters?.map((item, index) => (
                  //       <Text>{index + 1}</Text>
                  //     )),
                  // },
                  { title: "የመራጭ ስም", field: "voter.name_amharic" },
                  { title: "Name", field: "voter.name", defaultSort: "asc" },
                  { title: "phone", field: "voter.phone" },
                  {
                    title: "Total Share",
                    field: "shares",

                    render: (rowData) => (
                      <Text>
                        {Number(
                          Math.round(rowData.shares * 10) / 10
                        ).toLocaleString()}
                      </Text>
                    ),
                  },
                  {
                    title: "Total Capital",
                    field: "totalCapital",
                    export: false,
                    render: (rowData) => (
                      <Text>
                        {Number(
                          Math.round(rowData.shares * 500 * 10) / 10
                        ).toLocaleString()}
                      </Text>
                    ),
                    // export: (rowData) =>
                    //   Number(Math.round(rowData?.shares * 500 * 10) / 10),
                  },

                  {
                    title: "Remove Vote",
                    field: "actions",
                    export: false,
                    render: (rowData) =>
                      rowData?.is_finalized == 0 ? (
                        <Button
                          leftIcon={<DeleteIcon />}
                          bg={"red.500"}
                          color={"white"}
                          variant="solid"
                          _hover={{ bg: "#B76A1D" }}
                          onClick={() => {
                            deleteNominee.mutate(rowData.id);
                          }}
                        >
                          Delete
                        </Button>
                      ) : null,
                  },
                ]}
                data={rowData.voters}
                options={{
                  exportAllData: true,
                  exportButton: true,
                  exportFileName: `${rowData.name} Voters`,

                  sorting: [
                    {
                      id: "voter.name",
                      desc: false,
                    },
                  ],
                  // exportCsv: (columns, data) => {
                  //   const newData = data.map((item) => ({
                  //     name: item.voter.name,
                  //     phone: item.voter.phone,
                  //     shares: item.shares,
                  //   }));
                  //   const exportData = [columns, ...newData];
                  //   exportData.push(["Total", "", rowData.votes_share, "", ""]);
                  //   const csv = exportData
                  //     .map((row) =>
                  //       row.map((item) => (item === null ? "" : item)).join(",")
                  //     )
                  //     .join("\n");
                  //   return csv;
                  // },
                }}
              />
            </Box>
          );
        }}
      />
    </Box>
  );
};
