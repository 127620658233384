import {
  Box,
  Button,
  Checkbox,
  Heading,
  SimpleGrid,
  VStack,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { customStyles } from "../Meeting/Attendance";

import { useLocation, useNavigate, useParams } from "react-router-dom";
const MultiVote = () => {
  const [voter, setVoter] = React.useState(null);
  const [proxy, setProxy] = React.useState(null);
  const [nominee, setNominee] = React.useState([]);
  const { token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const state = useLocation();
  const navigate = useNavigate();

  const stakeholderData = useQuery(
    "stakeholderDatas",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  const { meetingId } = useParams();
  const getNominees = useQuery(
    `getNominees"`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}votes/nominees/${meetingId}`,
        {
          headers,
          params: {
            agenda_id: state?.agendaId,
          },
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,

      retry: true,
      enabled: !!token && !!meetingId,
    }
  );

  const [isShareholder, setIsShareholder] = React.useState(true);
  const toast = useToast();

  const voteMutation = useMutation(
    async (data) => {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}votes`, data, {
        headers,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: "Vote submitted.",
          description: "Vote submitted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setNominee([]);
        navigate("/voting");
      },
      onError: (err) => {
        toast({
          title: `${err?.response?.data?.message ?? "An error occurred"}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  const getAttendant = useQuery(
    ["attended", "meetingId"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}attended/${meetingId}`,
        {
          headers,
          params: {
            type: "voters",
            agenda_id: state?.state?.agendaId,
          },
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  const eachVoteMutation = (data) => {
    data?.map((item) => {
      voteMutation.mutate({
        nominee_id: item?.id,
        stakeholder_id: isShareholder ? voter : null,
        proxy_id: !isShareholder ? voter : null,
        meeting_id: meetingId,
      });
    });
  };
  console.log(getAttendant?.data?.data?.data, "getAttendant");

  return (
    <VStack>
      {" "}
      <Wrap
        direction={"row"}
        gap={4}
        spacing={4}
        m={8}
        alignItems={"center"}
        justify={"space-around"}
        w={"100%"}
      >
        {/* <Checkbox
          size="lg"
          colorScheme="green"
          defaultIsChecked={isShareholder}
          checked={isShareholder}
          onChange={() => {
            setIsShareholder(!isShareholder);
            setVoter(null);
            setProxy(null);
          }}
        >
          Is Shareholder
        </Checkbox> */}

        <WrapItem
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: 3,
          }}
        >
          <Heading size="md" my={1}>
            Select Voter
          </Heading>

          <Select
            styles={customStyles}
            placeholder="Select Voter"
            // defaultValue={voter[0] ?? []}
            isDisabled={getAttendant?.data?.data?.data?.length <= 0}
            options={getAttendant?.data?.data?.data}
            getOptionLabel={(option) => option?.attendable?.name}
            getOptionValue={(option) => option?.attendable?.id}
            isLoading={getAttendant.isLoading}
            isClearable
            onChange={(e) => {
              setVoter(e?.attendable?.id);

              if (e?.type === "Stakeholder") {
                setIsShareholder(true);
              } else {
                setIsShareholder(false);
              }
            }}
          />
        </WrapItem>

        {/* <WrapItem
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: 3,
          }}
        >
          <Heading size="md" my={1}>
            Select From Proxy
          </Heading>

          <Select
            styles={customStyles}
            placeholder="Select Nominees"
            isDisabled={
              stakeholderData?.data?.data?.data?.length <= 0 || isShareholder
            }
            options={attendeeData?.data?.data?.data?.proxies}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option}
            isLoading={stakeholderData.isLoading}
            // value={nominee}
            isClearable
            // isMulti
            onChange={(e) => {
              setProxy(e);
            }}
          />
        </WrapItem> */}
      </Wrap>
      <Box my={8} width="70%">
        <Heading size="sm" mb={2} textAlign="start">
          List Nominees
        </Heading>

        <SimpleGrid columns={3} gap={2}>
          {getNominees?.data?.data?.data?.nominees?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                shadow: "md",
                width: "100%",
                my: 2,
              }}
            >
              <Checkbox
                size="lg"
                colorScheme="green"
                defaultChecked={nominee?.includes(item)}
                sx={{
                  width: "100%",
                  justifyContent: "space-around",
                  py: 2,
                }}
                onChange={() => {
                  nominee?.includes(item)
                    ? setNominee(nominee?.filter((i) => i !== item))
                    : setNominee([...nominee, item]);
                }}
                checked={nominee?.includes(item)}
              >
                {item?.name}
              </Checkbox>
            </Box>
          ))}
        </SimpleGrid>
        <Button
          colorScheme="green"
          size="lg"
          mt={4}
          // disabled={nominee?.length <= 0 || !voter?.id}
          isLoading={voteMutation.isLoading}
          onClick={() => {
            eachVoteMutation(nominee);
          }}
        >
          Submit Vote
        </Button>
      </Box>
    </VStack>
  );
};

export default MultiVote;
