import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import axios from "axios";
import moment from "moment";
import React from "react";
import { BsChatSquareQuote, BsThreeDotsVertical } from "react-icons/bs";
import { RiFileShredLine, RiRestartLine, RiShutDownLine } from "react-icons/ri";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { AiTwotoneCalendar } from "react-icons/ai";

export default function MeetingCard(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const { token, type } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const toast = useToast();
  const deleteMeetingMutation = useMutation(
    async (meetingId) => {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}meetings/${meetingId}`,
        {
          headers,
        }
      );
    },
    {
      onSuccess: () => {
        toast({
          title: "Meeting deleted successfully",
          description: "Meeting deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        props.refetch();
        setIsOpen(false);
      },
      onError: (error) => {
        toast({
          title: "Meeting deleted failed",
          description: `${error.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const cancelMeetingMutation = useMutation(
    async (meetingId) => {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meetings/cancel/${meetingId}`,
        {
          headers,
        }
      );
    },
    {
      onSuccess: () => {
        toast({
          title: "Meeting canceled successfully",
          description: "Meeting canceled successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        props.refetch();
        setIsOpen(false);
      },
      onError: (error) => {
        toast({
          title: "Meeting canceled failed",
          description: `${error.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const startMeetingMutation = useMutation(
    async (meetingId) => {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meetings/start/${meetingId}`,
        {
          headers,
        }
      );
    },
    {
      onSuccess: () => {
        toast({
          title: "Meeting started successfully",
          description: "Meeting started successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        props.refetch();
        setIsOpen(false);
      },
      onError: (error) => {
        toast({
          title: "Start Meeting failed",
          description: `${error.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const endMeetingMutation = useMutation(
    async (meetingId) => {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meetings/end/${meetingId}`,
        {
          headers,
        }
      );
    },
    {
      onSuccess: () => {
        toast({
          title: "Meeting ended successfully",
          description: "Meeting ended successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        props.refetch();
        setIsOpen(false);
      },
      onError: (error) => {
        toast({
          title: "End Meeting failed",
          description: `${error.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  // deployed
  var url = "https://live.meeting.yeabrak.com/?id=" + props.meetingId;
  // local
  // var url = "http://localhost:5173/?id=" + props.meetingId;

  return (
    <>
      <Popover
        placement="bottom"
        isLazy
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Center py={6}>
          <Box
            minW={"400px"}
            // w={"full"}
            bg={useColorModeValue("white", "gray.900")}
            boxShadow={"2xl"}
            rounded={"lg"}
            p={6}
            pt={20}
            textAlign={"center"}
            direction={"column"}
            position={"relative"}
          >
            <PopoverTrigger sx={{}}>
              <IconButton
                sx={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  m: "1rem",
                  mb: "8rem",
                  borderRadius: "full",
                  boxShadow: "lg",
                }}
                aria-label="More server options"
                icon={<BsThreeDotsVertical />}
                variant="solid"
                w="fit-content"
                onClick={() => setIsOpen(!isOpen)}
              />
            </PopoverTrigger>
            {/* <Avatar
              size={"xl"}
              src={"https://addisbiz.com/wp-content/uploads/nib-insurance.jpg"}
              alt={"Avatar Alt"}
              mb={4}
              pos={"relative"}
            /> */}
            <Heading
              fontSize={"2xl"}
              fontFamily={"body"}
              textTransform={"capitalize"}
            >
              {props.meetingTittle}
            </Heading>
            <Text
              fontWeight={600}
              color={"gray.500"}
              mb={4}
              textTransform={"capitalize"}
            >
              {moment(props?.meetingTime).format("ll")}{" "}
            </Text>
            <Text
              textAlign={"center"}
              color={useColorModeValue("gray.700", "gray.400")}
              px={3}
              textTransform={"capitalize"}
            >
              {props.location}
            </Text>

            <Badge
              sx={{
                mt: 4,
                borderRadius: "full",
                // px: 1,
                p: "0.5rem",
              }}
              colorScheme="green"
            >
              {props?.today && props?.status === "Started"
                ? "Started "
                : props?.status === "Pending" && props?.today
                ? "Today"
                : props?.status}
            </Badge>

            <Stack mt={8} direction={"row"} spacing={4}>
              {props?.today && (
                <Button
                  flex={1}
                  fontSize={"sm"}
                  rounded={"full"}
                  _focus={{
                    bg: "gray.200",
                  }}
                  onClick={props.onClickAttendees}
                >
                  Add Attendances
                </Button>
              )}
              <Button
                display={type === "ADMIN" ? "flex" : "none"}
                flex={1}
                fontSize={"sm"}
                rounded={"full"}
                bg={"blue.400"}
                color={"white"}
                boxShadow={
                  "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                }
                _hover={{
                  bg: "blue.500",
                }}
                _focus={{
                  bg: "blue.500",
                }}
                onClick={props.onClick}
              >
                Add Agenda
              </Button>
              <Button
                flex={1}
                fontSize={"sm"}
                rounded={"full"}
                bg={"blue.400"}
                color={"white"}
                boxShadow={
                  "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                }
                _hover={{
                  bg: "blue.500",
                }}
                _focus={{
                  bg: "blue.500",
                }}
                onClick={props.viewMeetingOnClick}
              >
                View Meeting
              </Button>
            </Stack>
          </Box>
        </Center>
        <Flex justifyContent="center" mt={4}>
          <PopoverContent w="fit-content" _focus={{ boxShadow: "" }}>
            <PopoverArrow />
            <PopoverBody>
              <Stack>
                {props?.status === "Pending" && type === "ADMIN" && (
                  <Button
                    w="194px"
                    variant="ghost"
                    rightIcon={<BsChatSquareQuote />}
                    justifyContent="space-between"
                    fontWeight="normal"
                    fontSize="sm"
                    onClick={() =>
                      navigate(`/meeting/update/${props.meetingId}`, {
                        state: {
                          meetingTittle: props.meetingTittle,
                          meetingTime: props.meetingTime,
                          location: props.location,
                          meetingId: props.meetingId,
                          note: props.note,
                        },
                      })
                    }
                  >
                    Update
                  </Button>
                )}
                {(props?.start_meeting || props?.today) && (
                  <Button
                    w="194px"
                    variant="ghost"
                    rightIcon={<RiRestartLine />}
                    justifyContent="space-between"
                    fontWeight="normal"
                    colorScheme="green"
                    fontSize="sm"
                    onClick={() => {
                      startMeetingMutation.mutate(props.meetingId);
                    }}
                  >
                    Start
                  </Button>
                )}
                {(props?.start_meeting || props?.today) && (
                  <Button
                    w="194px"
                    variant="ghost"
                    rightIcon={<RiRestartLine />}
                    justifyContent="space-between"
                    fontWeight="normal"
                    colorScheme="green"
                    fontSize="sm"
                    onClick={() => {
                      // // startMeetingMutation.mutate(props.meetingId);
                      // window.location.href = url;
                      window.open(url, "_blank");
                    }}
                  >
                    View live report
                  </Button>
                )}
                {props?.status === "Started" && (
                  <Button
                    w="194px"
                    variant="ghost"
                    rightIcon={<RiShutDownLine />}
                    justifyContent="space-between"
                    fontWeight="normal"
                    colorScheme="red"
                    fontSize="sm"
                    onClick={() => {
                      endMeetingMutation.mutate(props.meetingId);
                    }}
                  >
                    End
                  </Button>
                )}
                {props?.status === "Cancelled" && (
                  <Button
                    w="194px"
                    variant="ghost"
                    rightIcon={<RiShutDownLine />}
                    justifyContent="space-between"
                    fontWeight="normal"
                    colorScheme="red"
                    fontSize="sm"
                    onClick={() => {
                      cancelMeetingMutation.mutate(props.meetingId);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {(props?.status === "Pending" ||
                  props?.status === "Cancelled" ||
                  props?.status === "Not_Hosted") && (
                  <Button
                    w="194px"
                    variant="ghost"
                    display={type === "ADMIN" ? "flex" : "none"}
                    rightIcon={<RiFileShredLine />}
                    justifyContent="space-between"
                    fontWeight="normal"
                    colorScheme="red"
                    fontSize="sm"
                    onClick={() =>
                      deleteMeetingMutation.mutate(props.meetingId)
                    }
                  >
                    Delete
                  </Button>
                )}
                <Button
                  w="194px"
                  variant="ghost"
                  rightIcon={<AiTwotoneCalendar />}
                  justifyContent="space-between"
                  fontWeight="normal"
                  colorScheme="green"
                  fontSize="sm"
                  onClick={() =>
                    navigate(`/meeting/attendees/${props.meetingId}`, {
                      state: {
                        meetingTittle: props?.fullTittle,
                      },
                    })
                  }
                >
                  View Attendances
                </Button>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Flex>
      </Popover>
    </>
  );
}
