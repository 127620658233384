import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { Formik, Field } from "formik";
import React from "react";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment/moment";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import Select from "react-select";
import { colourStyles } from "./AddNomine";

const validationSchema = Yup.object({
  meeting: Yup.string()
    .required("Meeting title is required")
    .min(8, "must be at least 8 character"),
  location: Yup.string().required("Meeting location is required"),
  note: Yup.string(),
  time: Yup.date().required("Meeting time is required"),
});

export const UpdateMeeting = () => {
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  console.log({ params });
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const updateMeetingMutation = useMutation(
    async (newData) =>
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}meetings/${params.meetingId}`,
        newData,

        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        toast({
          title: "Meeting created.",
          description: "Created new meeting successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        meetingData.refetch();
        navigate("/meeting");

        queryClient.invalidateQueries("meetingDataForForm");
      },
      onError: (error) => {
        toast({
          title: "Meeting creation failed.",
          description: error?.response?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    },
    {
      retry: false,
    }
  );
  const meetingData = useQuery(
    "meetingDataForForm",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meetings/${params.meetingId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  React.useEffect(() => {
    queryClient.invalidateQueries("meetingDataForForm");
    meetingData.refetch();
  }, [params.meetingId]);

  const initialValues = {
    meeting: state?.meetingTittle,
    location: state?.location,
    note: state?.note,
    time: state?.meetingTime,
    meetingId: state.meetingId,
  };

  const meetingTypeData = useQuery(
    ["meetingTypeData"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}meeting-types`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  return (
    <VStack>
      <Text
        sx={{
          color: "#B51D64",
          fontWeight: "bold",
          fontSize: "25px",
          my: 5,
        }}
      >
        Update {"  "}
        <span
          style={{
            fontWeight: "bolder",
            color: "green",
            textDecoration: "underline",
          }}
        >{`${state?.meetingTittle}`}</span>
        {"  "}
        Meeting
      </Text>
      <Flex
        sx={{
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
          justifyItems: "center",
          my: 5,
          pb: 10,
          borderBottom: "1px solid gray",
        }}
      >
        <Box bg="white" p={6} w={"40%"}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              updateMeetingMutation.isLoading && actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {(props) => (
              <VStack
                as={"form"}
                spacing={4}
                align="flex-start"
                onSubmit={props.handleSubmit}
              >
                <FormControl
                  isInvalid={!!props.errors.meeting && props.touched.meeting}
                >
                  <FormLabel htmlFor="Meeting Title">Meeting</FormLabel>
                  <Field
                    as={Input}
                    p={4}
                    placeholder="Meeting Title"
                    id="meeting"
                    name="meeting"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.meeting}
                  />
                  <FormErrorMessage> {props.errors.meeting} </FormErrorMessage>
                </FormControl>
                {/* create select to choose meeting type  */}
                <FormControl
                  isInvalid={!!props.errors.type && props.touched.type}
                >
                  <FormLabel htmlFor="type">Meeting Type</FormLabel>
                  <Field
                    as={Select}
                    placeholder="Select meeting type"
                    id="type"
                    name="type"
                    variant="filled"
                    loading={meetingTypeData.isFetching}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    onChange={(option) => {
                      props.setFieldValue("type", option.value);
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.type}
                    style={colourStyles}
                    options={meetingTypeData.data?.data}
                  />
                  <FormErrorMessage>{props.errors.type}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!props.errors.location && props.touched.location}
                >
                  <FormLabel htmlFor="Location">Location</FormLabel>
                  <Field
                    p={4}
                    as={Input}
                    placeholder="Location"
                    id="location"
                    name="location"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.location}
                  />
                  <FormErrorMessage>{props.errors.location}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!props.errors.note && props.touched.note}
                >
                  <FormLabel htmlFor="note">Note</FormLabel>
                  <Field
                    p={4}
                    as={Input}
                    placeholder="Enter note for meeting"
                    id="note"
                    name="note"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.note}
                  />
                </FormControl>
                <FormControl
                  isInvalid={!!props.errors.time && props.touched.time}
                >
                  <FormLabel htmlFor="time">Time</FormLabel>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="time"
                      name="time"
                      format="MM/dd/yyyy"
                      minDate={new Date()}
                      value={props.values.time}
                      onChange={(value) =>
                        props.setFieldValue(
                          "time",

                          moment(value).format("YYYY-MM-DD HH:mm:ss")
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <FormErrorMessage>{props.errors.time}</FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  disabled={!!props.errors.location || !!props.errors.meeting}
                  colorScheme="purple"
                  width="full"
                  isLoading={
                    props.isSubmitting || updateMeetingMutation.isLoading
                  }
                  onClick={() => {
                    updateMeetingMutation.mutate({
                      title: props.values.meeting,
                      location: props.values.location,
                      time: props.values.time,
                      note: props.values.note,
                    });
                  }}
                >
                  Update Meeting
                </Button>
                <Button
                  colorScheme="red"
                  width="full"
                  onClick={() => {
                    navigate("/meeting");
                  }}
                >
                  Back
                </Button>
              </VStack>
            )}
          </Formik>
        </Box>
      </Flex>
    </VStack>
  );
};
