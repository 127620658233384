import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { SyncLoader } from "react-spinners";
import * as Yup from "yup";
import { useAuth } from "../../utilities/hooks/auth-hook";

const TaxUpdate = ({ isOpen, onClose, userId }) => {
  const [error, setError] = React.useState(null);
  const { user, token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const previousTax = useQuery(
    `PreviousTax`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}taxes`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: false,
      enabled: !!token,
    }
  );
  console.log("previousTax", previousTax?.data?.data?.data);

  const updateTax = useMutation(
    async (data) =>
      await axios.patch(
        ` ${process.env.REACT_APP_BACKEND_URL}taxes/${previousTax?.data?.data?.data?.id}`,
        data,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    rate: previousTax?.data?.data?.data?.rate,
    start_date: previousTax?.data?.data?.data?.start_date,
    end_date: previousTax?.data?.data?.data?.end_date,
  };
  console.log("initialValues", previousTax?.data?.data?.data);
  const validationSchema = Yup.object().shape({
    rate: Yup.number("must be a number")
      .positive("Rate must be positive")
      .typeError("Rate must be a number")
      .moreThan(0, "Rate must be greater than 0")
      .lessThan(1, "Rate must be less than 1")
      .required("Rate is required"),
    start_date: Yup.date("must be a date")
      .typeError("Start date must be a date")
      .min(new Date(Date.now()), "Start date must be after today")
      .required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date must be after start date")
      .min(new Date(Date.now()), "End date must be after today")
      .typeError("End date must be a date"),
  });

  const onSubmit = async (values) => {
    const { rate, start_date, end_date } = values;

    try {
      updateTax.mutate(
        {
          rate,
          start_date,
          end_date,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: " Tax updated successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
            previousTax.refetch();
          },
          onError: (responseDataError) => {
            console.log({ responseDataError });
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                "Tax update failed.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {!previousTax?.isFetching ? (
          <Center py={5}>
            {previousTax?.isSuccess ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {(values) => {
                  console.log(values?.values);
                  return (
                    <Form>
                      <Box my={4}>
                        <Center
                          color="#AFAFAF"
                          fontSize="25px"
                          fontWeight="semibold"
                        >
                          Update Tax
                        </Center>
                        <Divider color="#333" my={4} />
                      </Box>
                      <Stack fontSize="18px" justify="center" spacing={4}>
                        <Stack justify="center" spacing={4}>
                          <Field name="rate">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                form={form}
                                title="Tax Rate"
                                htmlFor="rate"
                                error={values.errors.rate}
                                touched={values.touched.rate}
                              />
                            )}
                          </Field>
                          <Field name="start_date">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                form={form}
                                title="Start Date"
                                type={"date"}
                                htmlFor="start_date"
                                error={values.errors.start_date}
                                touched={values.touched.start_date}
                                min={new Date(Date.now())}
                              />
                            )}
                          </Field>
                          <Field name="end_date">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                type={"date"}
                                form={form}
                                title="End Date"
                                htmlFor="end_date"
                                error={values.errors.end_date}
                                touched={values.touched.end_date}
                                min={values.values.start_date}
                              />
                            )}
                          </Field>
                        </Stack>
                        <Center>
                          <Button
                            my={5}
                            colorScheme="facebook"
                            disabled={
                              values?.values.rate === null ||
                              values?.values.start_date === null ||
                              values?.values.end_date === null ||
                              !values.isValid ||
                              values?.errors?.rate ||
                              values?.errors?.start_date ||
                              values?.errors?.end_date
                            }
                            _hover={
                              values.isValid &&
                              values.dirty && {
                                opacity: "0.8",
                              }
                            }
                            type={!updateTax?.isLoading ? "submit" : "button"}
                            isLoading={updateTax?.isLoading}
                            // onClick={() => {
                            //   console.log({ values });
                            // }}
                          >
                            Update Tax
                          </Button>
                        </Center>
                      </Stack>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Center>Check your connection and retry </Center>
            )}
          </Center>
        ) : (
          <Center alignSelf={"center"} minH="500px">
            <SyncLoader size={15} margin={2} color="#4962f2" />
          </Center>
        )}
      </ModalContent>
    </Modal>
  );
};

export default TaxUpdate;

export const CustomInput = ({
  field,
  title,
  htmlFor,
  type,
  error,
  touched,
  min,
  max,
  disabled,
  value,
}) => {
  return (
    <Box w={{ base: "100%", md: "70%" }}>
      <FormControl isInvalid={error && touched}>
        <Text fontSize="sm" fontWeight="semibold" color="#AFAFAF">
          {title}
        </Text>
        <Stack spacing="3px">
          <Input
            w={{ base: "280px", lg: "320px" }}
            {...field}
            id={htmlFor}
            placeholder={title}
            type={type}
            min={min}
            max={max}
            disabled={disabled}
            value={value}
            label={title}
          />
          {error && touched && <Text color="red">{error}</Text>}
        </Stack>
      </FormControl>
    </Box>
  );
};
