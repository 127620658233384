import { SettingsIcon } from "@chakra-ui/icons";
import { FiUserPlus } from "react-icons/fi";
import { GiBuyCard } from "react-icons/gi";
import { MdHowToVote, MdPointOfSale, MdViewAgenda } from "react-icons/md";

const Sss = GiBuyCard;

export const StoreManagerRoutes = [
  // { name: "Dashboard", to: "/dashboard", icon: <MdDashboard size={"1.7em"} /> },

  {
    name: "Meeting Management",
    to: "/meeting",
    icon: <MdPointOfSale size={"1.7em"} />,
  },
  {
    name: "Nominee Voting",
    to: "/voting",
    icon: <MdHowToVote size={"1.7em"} />,
  },
  {
    name: "Agenda Voting",
    to: "/agenda-voting",
    icon: <MdViewAgenda size={"1.7em"} />,
  },
  {
    name: "Proxy Management",
    to: "/proxy",
    icon: <Sss size={"1.7em"} />,
  },
  {
    name: "User Management",
    to: "/users",
    icon: <FiUserPlus size={"1.7em"} />,
  },

  {
    name: "Settings",
    to: "/utils",
    icon: <SettingsIcon size={"1.5em"} />,
  },
];
