import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Progress,
  Text,
  Wrap,
  WrapItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import { customStyles } from "../../Meeting/Attendance";

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      valueContainer: (provided, state) => ({
        ...provided,
        background: "#fff",
        borderColor: "#9e9e9e",
        minHeight: "5indicatorsContainer0px",
        height: "80px",
        boxShadow: state.isFocused ? null : null,
        zIndex: "14343",
      }),

      backgroundColor: isDisabled ? "red" : "whitesmoke",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "5px",
      marginBottom: "5px",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#000" : "#000"),
      },
    };
  },
};

export const AgendaVoting = () => {
  const { token } = useAuth();
  const [nomineeData, setNomineeData] = useState([]);
  const toast = useToast();
  const [meeting, selectMeeting] = useState({
    meetingId: "",
    meetingTitle: "",
  });

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const meetingData = useQuery(
    ["meetingDataForCard"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}meetings`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  const getAgenda = useQuery(
    ["getNominees", meeting?.meetingTitle],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}votes/agendas/${meeting?.meetingId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token && !!meeting?.meetingId,
      onSuccess: (res) => {
        console.log(res.data.data);
        setNomineeData(res?.data?.data?.agendas);
      },
    }
  );

  const deleteAgenda = useMutation(
    "deleteAgenda",
    async (id) =>
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}votes/agendas/${id}`,
        {
          headers,
        }
      ),
    {
      onSuccess: (res) => {
        toast({
          title: "Agenda Deleted",
          description: "Agenda Deleted Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        getAgenda.refetch();
      },
      onError: (err) => {
        toast({
          title: `Error ${err.response.status}`,
          description: `${err.response.data.message}`,

          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const navigate = useNavigate();
  React.useEffect(() => {
    setNomineeData([]);
  }, [meetingData.data?.data, meeting?.meetingId]);

  React.useEffect(() => {
    if (meetingData.data?.data?.data?.length > 0) {
      selectMeeting({
        meetingId: meetingData.data?.data?.data[0]?.id,
        meetingTitle: meetingData.data?.data?.data[0]?.title,
      });
    }
  }, [meetingData.data?.data]);

  const finalizeAgendas = useMutation(
    "finalizeNomineeVotes",
    async (id) =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}votes/agendas/finalize/${id}`,
        { headers }
      ),
    {
      onSuccess: (res) => {
        toast({
          title: "Agenda Votes Finalized",
          description: " Agenda Votes Finalized Successfully ",
          status: "success",
          duration: 9000,

          isClosable: true,
        });
        getAgenda.refetch();
      },
      onError: (err) => {
        toast({
          status: "error",
          title: "Error",
          description: ` ${err?.response?.data?.message}`,
        });
      },
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "80%",
      }}
    >
      {/* <AddMeetingTemplate isOpen={isOpen} onClose={onClose} /> */}

      <Heading margin={"20px"} textAlign={"center"} fontSize="xl">
        Voting Management
      </Heading>
      <Wrap direction={"row"} mx={10} justify={"space-around"}>
        <WrapItem flexDirection={"column"} align="start">
          <Heading size="md" my={1}>
            Select Meeting
          </Heading>

          <Select
            styles={customStyles}
            placeholder="Select Meeting"
            defaultValue={
              meetingData.data?.data?.data?.length > 0
                ? {
                    id: meetingData.data?.data?.data[0]?.id,
                    title: meetingData.data?.data?.data[0]?.title,
                  }
                : null
            }
            isLoading={meetingData.isLoading}
            isDisabled={meetingData.isLoading}
            options={meetingData.data?.data?.data}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            isClearable
            onChange={(e) => {
              selectMeeting({
                meetingId: e?.id,
                meetingTitle: e?.title,
              });
            }}
          />
        </WrapItem>
        <WrapItem>
          <Button
            sx={{
              backgroundColor: "#E82481",
              color: "#FFF",
              borderRadius: "10px",
              alignSelf: "center",
              _hover: {
                backgroundColor: "#E82481",
                color: "#FFF",
              },
            }}
            onClick={() => {
              finalizeAgendas.mutate(meeting?.meetingId);
            }}
            isLoading={finalizeAgendas.isLoading}
          >
            Finalize Vote
          </Button>
        </WrapItem>
      </Wrap>

      <MaterialTable
        isLoading={getAgenda.isLoading}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <Text
                sx={{
                  fontSize: "md",
                  fontWeight: "bold",
                }}
              >
                Select Meeting To Get list of Agenda
              </Text>
            ),
          },
        }}
        style={{
          zIndex: "0",
          margin: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        options={{
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          rowStyle: {
            color: "#000",
            backgroundColor: "#FFF",
          },
          searchFieldStyle: {
            backgroundColor: "#FFF",
            color: "#000",
          },

          padding: "default",
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Agenda List",
          sorting: true,
          initialState: {
            sorting: [
              {
                id: "percent",
                desc: true,
              },
              {
                id: "votes",
                desc: true,
              },
            ],
          },
        }}
        title="Agenda List"
        columns={[
          {
            title: "Agenda Title ",
            field: "title",
          },
          {
            title: "Percent",
            field: "percent",
            type: "numeric",
            defaultSort: "desc",
            render: (rowData) => (
              <Text>
                {Number(Math.round(rowData.percent * 10) / 10).toLocaleString()}
              </Text>
            ),
          },
          {
            title: "Total Votes(Share)",
            field: "votes",
            type: "numeric",
            defaultSort: "desc",

            render: (rowData) => (
              <Text>
                {Number(Math.round(rowData.votes * 10) / 10).toLocaleString()}
              </Text>
            ),
          },

          {
            title: "Vote",
            field: "actions",
            render: (rowData) =>
              rowData?.is_finalized == 0 && (
                <Button
                  // leftIcon={<MdOutlineHowToVote/>}
                  bg={"#B76A1D"}
                  color={"white"}
                  variant="solid"
                  _hover={{ bg: "#B76A1D" }}
                  onClick={() => {
                    navigate(`/agenda-voting/${rowData?.id}`, {
                      state: {
                        meetingId: meeting?.meetingId,
                        agendaTitle: rowData?.title,
                        meetingTitle: meeting?.meetingTitle,

                        proxies: getAgenda.data?.data?.data?.proxies,
                        shareholders: getAgenda.data?.data?.data?.stakeholders,
                      },
                    });
                  }}
                >
                  Add Vote
                </Button>
              ),
          },
        ]}
        data={nomineeData}
        detailPanel={(rowData) => {
          return (
            <Box>
              <MaterialTable
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <Text
                        sx={{
                          fontSize: "md",
                          fontWeight: "bold",
                        }}
                      >
                        No Votes Yet
                      </Text>
                    ),
                  },
                }}
                style={{
                  border: "1px solid #E2E8F0",
                  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                  borderRadius: "15px",
                  marginLeft: "7%",
                  marginRight: "7%",
                }}
                title="List of Voters"
                columns={[
                  { title: "Name", field: "voter.name" },
                  { title: "Phone", field: "voter.phone" },
                  {
                    title: "Total Share",
                    field: "shares",
                    render: (rowData) => (
                      <Text>
                        {Number(
                          Math.round(rowData.shares * 10) / 10
                        ).toLocaleString()}
                      </Text>
                    ),
                  },

                  {
                    title: "Remove Vote",
                    field: "actions",
                    render: (rowData) =>
                      rowData?.is_finalized == 0 && (
                        <Button
                          leftIcon={<DeleteIcon />}
                          bg={"red.500"}
                          color={"white"}
                          variant="solid"
                          _hover={{ bg: "#B76A1D" }}
                          onClick={() => {
                            deleteAgenda.mutate(rowData.id);
                          }}
                        >
                          Delete
                        </Button>
                      ),
                  },
                ]}
                data={rowData.voters}
              />
            </Box>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </Box>
  );
};
