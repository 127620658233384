import { Box, Button, Progress, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../utilities/hooks/auth-hook";
export const AttendantTable = ({ meetingId, refetch }) => {
  const toast = useToast();

  const { token, type } = useAuth();
  const queryClient = useQueryClient();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getAttendant = useQuery(
    ["getAttendant"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}attended/${meetingId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      retry: true,
      enabled: !!token,
    }
  );
  useEffect(() => {
    if (refetch) getAttendant.refetch();
  }, [refetch]);
  const deleteAttendance = useMutation(
    async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}attendances/${id}`,
        {
          headers,
        }
      );
    },
    {
      onSuccess: () => {
        getAttendant.refetch();
        queryClient.invalidateQueries("getAttendant");

        toast({
          title: "Deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (err) => {
        toast({
          title: `${err?.response?.data?.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  return (
    <Box w={"100%"}>
      <MaterialTable
        isLoading={getAttendant.isLoading}
        style={{
          zIndex: "0",
          margin: "3rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        title="Attendant List"
        columns={[
          { title: "ስም", field: "attendable.name_amharic" },
          {
            title: "Name",
            field: "attendable.name",
            sorting: true,
            type: "string",
            defaultSort: "asc",
          },
          { title: "Phone Number", field: "attendable.phone" },

          {
            title: "Share",
            field: "shares",
            render: (rowData) => {
              return (
                <Text>
                  {Number(
                    Math.round(rowData.shares * 10) / 10
                  ).toLocaleString()}
                </Text>
              );
            },
          },
          {
            title: "Capital",
            field: "attendable.current_capital",
            render: (rowData) => (
              <Text>
                {Number(
                  Math.round(rowData.attendable.current_capital * 10) / 10
                ).toLocaleString()}
              </Text>
            ),
          },
          {
            title: "Shares",
            field: "type",

            render: (rowData) => {
              return (
                <Text
                  sx={{
                    fontWeight: "bolder",
                  }}
                >
                  {rowData.type}
                </Text>
              );
            },
          },
          {
            title: "Delete",

            render: (rowData) => (
              <Button
                sx={{
                  display: type === "ADMIN" ? "flex" : "none",

                  color: "gray.900",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  borderColor: "red.500",
                  backgroundColor: "red.500",
                }}
                onClick={() => {
                  deleteAttendance.mutate(rowData?.id);
                }}
              >
                Delete
              </Button>
            ),
          },
        ]}
        data={getAttendant?.data?.data?.data}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Attendant List",
          pageSizeOptions: [10, 20, 30, 50, 75, 100],
          pageSize: 20,
          sorting: true,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
        }}
      />
    </Box>
  );
};
