import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import showPassIcon from "../assets/password/show.png";
import hidePassIcon from "../assets/password/hide.png";
import { useAuth } from "../utilities/hooks/auth-hook";
import { Logo } from "../utilities/components/Logo";
import { useMutation } from "react-query";
import axios from "axios";
import ErrorModal from "../utilities/components/ErrorModal";
import MainModal from "../utilities/components/MainModal";

const Login = () => {
  const { type, token, user, login } = useAuth();

  console.log({ type, token, user });

  const toast = useToast();

  const [showPassword, setShowPassword] = useState(false);
  // modal states
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [registerSchool, setRegisterSchool] = useState(false);
  const [passChange, setPassChange] = useState(false);

  const navigate = useNavigate();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const loginMutation = useMutation(
    async (newLogin) =>
      await axios.post(process.env.REACT_APP_BACKEND_URL + "login", newLogin, {
        headers,
      }),
    {
      retry: false,
    }
  );

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required").min(8, "Too Short!"),
  });

  const onSubmit = async ({ email, password }) => {
    try {
      loginMutation.mutate(
        {
          email,
          password,
        },
        {
          onSuccess: (responseData) => {
            // eslint-disable-next-line no-console
            console.log({ responseData });
            let user =
              responseData?.data?.data?.is_super === 1
                ? {
                    id: responseData?.data?.data?.id,
                    email: responseData?.data?.data?.email,
                    firstName: responseData?.data?.data?.first_name,
                    lastName: responseData?.data?.data?.last_name,
                    firstLogin: responseData?.data?.data?.first_login,
                    isSuper: true,
                  }
                : {
                    id: responseData?.data?.data?.id,
                    email: responseData?.data?.data?.email,
                    firstName: responseData?.data?.data?.first_name,
                    lastName: responseData?.data?.data?.last_name,
                    firstLogin: responseData?.data?.data?.first_login,
                  };

            if (responseData?.data?.success) {
              // if (responseData?.data?.data?.school === 0) {
              //   setRegisterSchool(responseData);
              // } else if (responseData?.data?.data?.first_login) {
              //   setPassChange(responseData);
              // } else {
              login(
                responseData?.data?.data?.role ?? "EMPLOYEE",
                responseData?.data?.data?.token,
                user,
                responseData?.data?.data?.permissions
              );
              toast({
                title: "Welcome to Nib Insurance Company 🎉",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              navigate("/");
              // }
            } else {
              onOpen();
            }
          },
          onError: (responseData) => {
            console.log({ responseData });
            onOpen();
          },
        }
      );
    } catch (err) {
      toast({
        title:
          loginMutation?.error?.message ||
          loginMutation?.error?.response?.data?.message ||
          "Error on login",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  console.log({ loginMutation });
  console.log({ isOpen });
  return (
    <Box bg={useColorModeValue("gray.50", "gray.800")} minH={"100vh"}>
      <Flex minH={"60vh"} align={"center"} justify={"center"}>
        <ErrorModal
          headers={headers}
          toast={toast}
          error={
            (loginMutation?.error?.message === "Network Error" &&
              loginMutation?.error?.message) ||
            loginMutation?.error?.response?.data?.data ||
            loginMutation?.error?.response?.data?.message ||
            "Error on login"
          }
          onClose={onClose}
          isOpen={isOpen}
          // onOpen={error ? onOpen : null}
        />
        {/* <PassChangeModal
          onClose={() => setPassChange(false)}
          isOpen={passChange}
        /> */}
        <MainModal
          passChange={passChange}
          isOpen={registerSchool || passChange}
        />
        <Stack justify={"center"} align={"center"}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={6} px={6}>
            <Stack align={"center"} spacing="12px">
              <Logo />
            </Stack>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(values) => {
                console.log({ values });
                return (
                  <Box>
                    <Form>
                      <Stack spacing={7}>
                        <Field name="email">
                          {({ field, form }) => (
                            <FormControl id="email">
                              {/* <FormLabel>Email address</FormLabel> */}
                              <Input
                                minW={{ base: "275px", md: "350px" }}
                                {...field}
                                placeholder="Email"
                                type="email"
                              />
                              {values?.errors?.email &&
                                values?.touched?.email && (
                                  <Text color="red">
                                    {values?.errors?.email}
                                  </Text>
                                )}
                            </FormControl>
                          )}
                        </Field>
                        <Field name="password">
                          {({ field, form }) => (
                            <FormControl id="password">
                              {/* <FormLabel>Password</FormLabel> */}
                              <InputGroup size="md">
                                <Input
                                  {...field}
                                  id="password"
                                  placeholder="password"
                                  type={showPassword ? "text" : "password"}
                                />
                                <InputRightElement width="4.5rem">
                                  <Button
                                    h="1.75rem"
                                    size="sm"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    <Img
                                      w="25px"
                                      src={
                                        showPassword
                                          ? showPassIcon
                                          : hidePassIcon
                                      }
                                    />
                                  </Button>
                                </InputRightElement>
                              </InputGroup>
                              {values?.errors?.password &&
                                values?.touched?.password && (
                                  <Text color="red">
                                    {values?.errors?.password}
                                  </Text>
                                )}
                            </FormControl>
                          )}
                        </Field>
                        <Button
                          isLoading={loginMutation?.isLoading}
                          disabled={!(values.isValid && values.dirty)}
                          type={!loginMutation?.isLoading ? "submit" : "button"}
                          bg={"#B76A1D"}
                          color={"white"}
                          variant="solid"
                          _hover={{
                            bg: "#FFF",
                            color: "#385898",
                            border: "1px solid #385898",
                          }}
                        >
                          Sign in
                        </Button>
                      </Stack>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Login;
